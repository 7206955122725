var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "magazineDisplayDetail" },
    [
      _c("share-button", {
        staticClass: "magazineDisplayDetail__shareTop",
        attrs: { type: "article", title: _vm.ogTitle, url: _vm.ogUrl },
      }),
      _vm._v(" "),
      _c("cms-text-link-block", [
        _c("div", {
          staticClass: "magazineDisplayDetail__text",
          domProps: { innerHTML: _vm._s(_vm.article.get("bodyText", "")) },
        }),
      ]),
      _vm._v(" "),
      _c("content-element-children", {
        attrs: { children: _vm.article.get("content.children", []) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }